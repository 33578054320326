<template>
  <ion-app>
    <ion-page>
      <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar v-if="isAppInstalled && isPushEnabled" slot="bottom">
          <ion-tab-button tab="jobs" href="/jobs">
            <ion-icon aria-hidden="true" :icon="rocket" />
            <ion-label>Вакансии</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="recs" href="/recs">
            <ion-icon aria-hidden="true" :icon="people" />
            <ion-label>Рекомендации</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="payouts" href="/payouts">
            <ion-icon aria-hidden="true" :icon="wallet" />
            <ion-label>Выплаты</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="news" href="/news">
            <ion-icon aria-hidden="true" :icon="newspaper" />
            <ion-label>Новости</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-page>
  </ion-app>
</template>

<script>
/* eslint-disable no-useless-escape */
/* eslint-disable */
import Landing from '@/components/Landing.vue'
import { initializeApp } from "firebase/app";
import { getToken, getMessaging } from "firebase/messaging";
import {
  IonApp,
  IonPage,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
} from "@ionic/vue";
import { rocket, people, wallet, newspaper } from "ionicons/icons";
// Your web app's Firebase configuration

// messaging.usePublicVapidKey("BG25GMu-S6v-zwAwqlQ6Jy3npfROWUOTlrWudIVL1D0Zok_wG94J3hjtvtcxei_R9G-5CmKtq5ueMwebzFJQ6Rw");

export default {
  components: {
    Landing,
    IonApp,
    IonPage,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
  },
  data() {
    return {
      rocket, people, wallet, newspaper,
      isAppInstalled: false,
      isPushEnabled: false,
      deferredPrompt: null,
      messagingToken: null,
      messaging: null,
    };
  },
  methods: {
    register() {
      return;
    },
    requestPermission() {
      if (!window.Notification) return;

      if (window.Notification.permission === "granted") {
        this.setToken();
      } else {
        window.Notification.requestPermission((value) => {
          if (value === "granted") {
            this.setToken();
          }
        });
      }
    },
    async setToken() {
      const token = await getToken(this.messaging, {
        vapidKey:
          "BG25GMu-S6v-zwAwqlQ6Jy3npfROWUOTlrWudIVL1D0Zok_wG94J3hjtvtcxei_R9G-5CmKtq5ueMwebzFJQ6Rw",
      });

      // Send token to server, save in user schema
      // but we're just manually doing it
      this.messagingToken = token;
      this.isPushEnabled = true;
    },
    installApp() {
      this.deferredPrompt.prompt();
      // Find out whether the user confirmed the installation or not
      const outcome = this.deferredPrompt.userChoice;
      // The deferredPrompt can only be used once.
      this.deferredPrompt = null;
      // Act on the user's choice
      if (outcome === "accepted") {
        console.log("User accepted the install prompt.");
        location.reload();
      } else if (outcome === "dismissed") {
        console.log("User dismissed the install prompt");
      }
    },
  },
  mounted() {
    window.addEventListener("DOMContentLoaded", () => {
      let displayMode = "browser tab";
      if (window.matchMedia("(display-mode: standalone)").matches) {
        displayMode = "standalone";
        this.isAppInstalled = true;
      }
      // Log launch display mode to analytics
      console.log("DISPLAY_MODE_LAUNCH:", displayMode);
    });
    if (window.Notification.permission === "granted") {
      this.setToken();
    }
  },
  created() {
    const firebaseConfig = {
      apiKey: "AIzaSyB9P4yWSu4qMYPK5PAkAz6ROv5J0Q7ZtA4",
      authDomain: "refme-bdd81.firebaseapp.com",
      projectId: "refme-bdd81",
      storageBucket: "refme-bdd81.appspot.com",
      messagingSenderId: "864414973502",
      appId: "1:864414973502:web:4790d41086a991fe00db6f",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    this.messaging = getMessaging(app);

    // messaging.onMessage((payload) => {
    //   console.log("Message received. ", payload);
    //   // ...
    // });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((reg) => {
          console.log("Registration succesful, scope: " + reg.scope);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (!("PushManager" in window)) {
      // Code to disable or hide push-related UI controls
      console.log("Push API is not supported");
      return;
    } else {
      console.log("Push API SUPPORTED");
    }
  },
};
</script>
