import { createRouter, createWebHashHistory } from "@ionic/vue-router";

const routes = [
  {
    path: "/:id",
    component: () => import("@/components/Landing.vue"),
  },
  {
    path: "/jobs",
    component: () => import("@/views/Jobs.vue"),
  },
  {
    path: "/recs",
    component: () => import("@/views/Recs.vue"),
  },
  {
    path: "/payouts",
    component: () => import("@/views/Payouts.vue"),
  },
  {
    path: "/news",
    component: () => import("@/views/News.vue"),
  },
  {
    path: "/vacancy/:id",
    component: () => import("@/Vacancy.vue"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
