<template>
  <ion-page>
    <ion-content class="ion-padding" color="light">
      <div v-if="!isAppInstalled && !isPushEnabled">
        <ion-img :alt="companyTitle" :src="logoUrl" />
        <p>
          Команда <strong>{{ companyTitle }}</strong> постоянно растет и
          развивается, открывая новые направления и проекты. Самое ценное — это
          люди, и мы ценим ту профессиональную и доверительную атмосферу,
          которую создаем вместе с сотрудниками.
        </p>
        <h2>Порекомендуй друга и получи деньги</h2>
        <p>После прохождения испытательного срока, получи деньги или бонусы.</p>
        <p>Для начала нужно установить приложение</p>
        <ion-button @click="installApp()" expand="block">Установить</ion-button>
      </div>
      <ion-accordion-group expand="inset" class="ion-margin-top">
        <ion-accordion value="first">
          <ion-item slot="header">
            <ion-label>Как порекомендовать друга?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ol>
              <li>
                Все актуальные вакансии для рекомендаций будут в этом приложени,
                открой подходящую.
              </li>
              <li>
                Обсуди с другом, интересно ли ему это предложение. Важно, чтобы
                человек знал о вакансии и компании и хотел пообщаться.
              </li>
              <li>
                Выбери нужную вакансии, нажми на кнопку «Рекомендовать» и
                заполни все поля
              </li>
            </ol>
          </div>
        </ion-accordion>
        <ion-accordion value="second">
          <ion-item slot="header">
            <ion-label>Как я узнаю, что мою рекомендацию увидели?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            О том, что рекомендация взята в работу, ты узнашь в пуш-уведомлении.
          </div>
        </ion-accordion>
        <ion-accordion value="third">
          <ion-item slot="header">
            <ion-label>Как я получу вознаграждение?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            После окончания контрольного периода рекомендованного друга
            специалист отдела кадров свяжется с тобой по телефону, чтобы
            обсудить процесс выплаты и подписание документов.
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </ion-content>
    <div v-if="isAppInstalled && !isPushEnabled">
      <ion-content class="ion-padding">
        <h1>Укажите свои данные</h1>
        <p>
          Чтобы мы знали кому начислить вознаграждение. Также мы запросим
          разрешение на отправку уведомлений
        </p>
        <button @click="requestPermission()">Продолжить</button>
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
/* eslint-disable no-useless-escape */
/* eslint-disable */
import { initializeApp } from "firebase/app";
import { getToken, getMessaging } from "firebase/messaging";
import {
  IonApp,
  IonPage,
  IonContent,
  IonAvatar,
  IonList,
  IonItem,
  IonImg,
  IonButton,
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
} from "@ionic/vue";

import axios from "axios";
// messaging.usePublicVapidKey("BG25GMu-S6v-zwAwqlQ6Jy3npfROWUOTlrWudIVL1D0Zok_wG94J3hjtvtcxei_R9G-5CmKtq5ueMwebzFJQ6Rw");

export default {
  components: {
    IonApp,
    IonPage,
    IonContent,
    IonAvatar,
    IonList,
    IonItem,
    IonImg,
    IonButton,
    IonLabel,
    IonAccordion,
    IonAccordionGroup,
  },
  data() {
    return {
      logoUrl: null,
      companyTitle: null,
      isAppInstalled: false,
      isPushEnabled: false,
      deferredPrompt: null,
      messagingToken: null,
      messaging: null,
    };
  },
  methods: {
    requestPermission() {
      if (!window.Notification) return;

      if (window.Notification.permission === "granted") {
        this.setToken();
      } else {
        window.Notification.requestPermission((value) => {
          if (value === "granted") {
            this.setToken();
          }
        });
      }
    },
    async setToken() {
      const token = await getToken(this.messaging, {
        vapidKey:
          "BG25GMu-S6v-zwAwqlQ6Jy3npfROWUOTlrWudIVL1D0Zok_wG94J3hjtvtcxei_R9G-5CmKtq5ueMwebzFJQ6Rw",
      });

      // Send token to server, save in user schema
      // but we're just manually doing it
      this.messagingToken = token;
      this.isPushEnabled = true;
    },
    installApp() {
      this.deferredPrompt.prompt();
      // Find out whether the user confirmed the installation or not
      const outcome = this.deferredPrompt.userChoice;
      // The deferredPrompt can only be used once.
      this.deferredPrompt = null;
      // Act on the user's choice
      if (outcome === "accepted") {
        console.log("User accepted the install prompt.");
        location.reload();
      } else if (outcome === "dismissed") {
        console.log("User dismissed the install prompt");
      }
    },
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    const self = this;
    axios
      .get(`${process.env.VUE_APP_API_HOST}/company/${this.$route.params.id}`)
      .then(function (response) {
        self.logoUrl = response.data.logo;
        self.companyTitle = response.data.title;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  created() {},
};
</script>

<style scoped>
ion-img::part(image) {
  box-shadow: 0 10px 20px #00000012;
  margin: 0 auto;
  width: 128px;
  border-radius: 16px;
}
</style>
